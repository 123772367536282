import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { CyData } from "../../../util/cypress";
import { ControlledContentEditable } from "../../common/ControlledContentEditable";

const StyledControlledContentEditable = styled(ControlledContentEditable)``;

type FontColor = "default" | "muted";

export const EditableContainer = styled.div<{
  $allCapsStyle: boolean;
  $large: boolean;
  $isEditing: boolean;
  $isEditable: boolean;
  $isValid: boolean;
  $placeholder: boolean;
  $fontColor: FontColor;
}>`
  display: flex;
  flex: 0 1 auto;
  justify-content: flex-start;
  min-width: 30px;
  max-height: 24px;

  margin-left: -3px;
  padding: 3px;
  overflow: hidden;

  color: ${({ $fontColor, $placeholder, theme }) =>
    $placeholder
      ? theme.fontColor.PLACEHOLDER
      : $fontColor === "muted"
        ? theme.fontColor.MUTED
        : theme.fontColor.DEFAULT};
  font-size: ${({ theme }) => theme.fontSize.SMALL};

  ${({ $allCapsStyle, theme }) =>
    $allCapsStyle &&
    `
      font-weight: ${theme.fontWeight.SEMI_BOLD};
      font-size: ${theme.fontSize.EXTRA_SMALL};
      text-transform: uppercase;
    `}

  ${({ $large, theme }) =>
    $large &&
    `
      font-size: ${theme.fontSize.DEFAULT};

      > ${StyledControlledContentEditable} {
        line-height: 18px;
      }
    `}

  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ $isEditable }) => $isEditable && "cursor: text;"}

  transition: box-shadow 0.2s ease;

  ${({ $isEditing, $isValid, theme }) =>
    $isEditing &&
    ($isValid
      ? `box-shadow: ${theme.boxShadow.FOCUS_PRIMARY}`
      : `box-shadow: inset 0 0 0 1px ${theme.intent.DANGER};`)}

  &:hover {
    ${({ $isEditable, $isEditing, theme }) =>
      !$isEditing &&
      $isEditable &&
      `box-shadow: inset 0 0 0 1px ${theme.borderColor.DEFAULT};`}
  }
`;

export interface EditableLabelProps {
  className?: string;
  label: string;
  placeholder?: string;
  onChangeLabel?: (newLabel: string | null) => void;
  editable?: boolean;
  fontColor?: FontColor;
  large?: boolean;
  allCapsStyle?: boolean;
  validateLabel?: (label: string) => boolean;
}

export const EditableLabel: React.ComponentType<EditableLabelProps> =
  React.memo(function EditableLabel({
    allCapsStyle = false,
    className,
    editable = true,
    fontColor = "muted",
    label,
    large = false,
    onChangeLabel,
    placeholder,
    validateLabel = () => true,
  }) {
    const [currentLabel, setCurrentLabel] = useState(label);
    const [isEditing, setIsEditing] = useState(false);
    // note: only validates when currentLabel !== props.label
    const [isValid, setIsValid] = useState(true);
    useEffect(() => {
      setCurrentLabel(label);
      setIsValid(true);
    }, [
      label,
      // CORE-4720 resync currentLabel when isEditing changes
      isEditing,
    ]);

    const clickCallback = useCallback(
      (evt: React.MouseEvent<HTMLDivElement>) => {
        if (editable) {
          evt.stopPropagation();
          setIsEditing(true);
        }
      },
      [editable],
    );

    const changeCallback = useCallback(
      (newValue: string) => {
        setCurrentLabel(newValue);
        setIsValid(validateLabel(newValue));
      },
      [validateLabel],
    );

    const cancelCallback = useCallback(() => {
      setCurrentLabel(label);
      setIsEditing(false);
      setIsValid(true);
    }, [label]);

    const saveCallback = useCallback(
      (newValue: string) => {
        if (isValid) {
          setIsEditing(false);
          onChangeLabel?.(newValue || null);
        }
      },
      [isValid, onChangeLabel],
    );

    const onBlur = useCallback(() => {
      setIsEditing(false);
      const valid = validateLabel(currentLabel);
      if (valid) {
        saveCallback(currentLabel);
      } else {
        // If blurring and the label is invalid, revert to the original label
        setCurrentLabel(label);
        setIsValid(true);
      }
    }, [currentLabel, label, saveCallback, validateLabel]);

    const showPlaceholder =
      !isEditing &&
      placeholder != null &&
      placeholder.length > 0 &&
      currentLabel.length === 0;

    return (
      <EditableContainer
        $allCapsStyle={allCapsStyle}
        $fontColor={fontColor}
        $isEditable={editable}
        $isEditing={isEditing}
        $isValid={isValid}
        $large={large}
        $placeholder={showPlaceholder}
        className={className}
        onClick={clickCallback}
      >
        <StyledControlledContentEditable
          content={showPlaceholder ? placeholder : currentLabel}
          data-cy={CyData.EDITABLE_LABEL}
          isEditing={isEditing}
          selectAllWhenEditingStarts={true}
          onBlur={onBlur}
          onCancel={cancelCallback}
          onChange={changeCallback}
          onSave={saveCallback}
        />
      </EditableContainer>
    );
  });
